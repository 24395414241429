<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">Fees</div>
                <!-- <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button> -->
            </div>
        </el-card>
        <div class="bg-white box-shadow border-r text-center p-20">
            <!-- <h3 class="color-3">RATE</h3>
            <el-table
                :data="pateList"
                stripe
                border
                class="w-100"
            >
                <el-table-column prop="trading_area" label="TRADING AREA" header-align="center" align="center" />
                <el-table-column prop="purchase" label="PURCHASE FEE" header-align="center" align="center" />
                <el-table-column prop="selling" label="SELLING FEE" header-align="center" align="center" />
            </el-table> -->
            <h3 class="color-3">CHARGING RATE</h3>
            <el-table
                :data="chargingRateList"
                stripe
                border
                class="w-100"
            >
                <el-table-column prop="currency" label="CURRENCY" header-align="center" align="center" />
                <el-table-column prop="min" label="MIN RECHARGE AMOUNT" header-align="center" align="center" />
                <el-table-column prop="recharge" label="FEE RECHARGE" header-align="center" align="center" />
                <el-table-column prop="withdraw" label="FEE WITHDRAW" header-align="center" align="center" />
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            list: [],
            loading: true,
            query: {},
            pateList: [
                {
                    trading_area: 'USDT',
                    purchase: '0.1%',
                    selling: '0.1%'
                }
            ],
            chargingRateList: [
                {
                    currency: 'USDT',
                    min: '20',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'BTC',
                    min: '0.01',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'ETH',
                    min: '0.1',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'LTC',
                    min: '10',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'EOS',
                    min: '20',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'LINK',
                    min: '2',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'ADA',
                    min: '100',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'XRT',
                    min: '2',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'XMR',
                    min: '1',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'IOTA',
                    min: '100',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                },
                {
                    currency: 'AR',
                    min: '50',
                    recharge: 'FREE',
                    withdraw: '0.02%'
                }
            ]
        }
    },
    watch: {
        $route: function (to, from){
            if (to.params.id == from.params.id) return
            this.fatchData()
        }
    },
    created: function () {
        // this.fatchList()
    },
    methods: {
        fatchList: function (){
            this.$request.post('notice', this.query).then(({ data }) => {
                for(const item of data.data){
                    this.list.push(item)
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
//
</style>